@media (max-width: 1200px) {
  .main {
    &-box {
      transform: translateX(calc(100% - 190px));
    }
  }
}
@media (max-width: 1140px) {
  *:not(video) {
    animation: none !important;
  }
  .main {
    &-box {
      transform: translateX(0);
      margin-right: 30px;
      width: calc(100% - 375px);
      h2 {
        font-size: 14px;
        height: auto;
        div {
          position: static;
          transform: translateX(0);
          justify-content: flex-start;
        }
      }
      h1 {
        font-size: 38px;
        line-height: 48px;
        text-align: left;
        margin-bottom: 24px;
        span {
          height: auto;
          b {
            position: static;
            transform: translateX(0);
            .now {
              position: static;
              transform: translateX(0);
              opacity: 1;
            }
          }
        }
      }
      .arrow {
        display: none;
      }
      ul {
        li {
          font-size: 14px;
          line-height: 22px;
          height: auto;
          margin-bottom: 12px;
          div {
            position: static;
            transform: translateX(0);
            justify-content: flex-start;
          }
        }
      }
      .get-started {
        & > div {
          position: static;
          transform: translateX(0);
        }
      }
    }
    .video-box {
      transform: translateX(0);
      height: 460px;
      width: 340px;
      padding: 12px;
    }
  }
}
@media (max-width: 890px) {
  .main {
    .container {
      padding: 30px 16px;
    }
    &-section {
      flex-direction: column;
    }
    &-box {
      margin-right: 0;
      margin-bottom: 30px;
      width: 100%;
      h2 {
        div {
          justify-content: center;
          white-space: normal;
          text-align: center;
          line-height: 24px;
        }
      }
      h1 {
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        span {
          b {
            white-space: normal;
          }
        }
      }
      ul {
        li {
          div {
            justify-content: center;
            white-space: normal;
          }
        }
      }
      .get-started {
        & > div {
          justify-content: center;
        }
      }
    }
    .video-box {
      //display: none;
    }
  }
}
